const ROWS_PER_PAGE = 15;
const calculateTotalPages = (totalPages: number, rowsPerPage = ROWS_PER_PAGE) => Math.ceil(totalPages / rowsPerPage);

export const useTotalPagesCount = (total: number) => {
    const count = calculateTotalPages(total);
    const isPaginationEnabled = count > 1;

    return {
        count,
        isPaginationEnabled,
    };
};
